import request from '@/utils/http'
export const upload = (data) => {
  return request('admin/get_sign', 'get', data)
}
// 快捷回复列表
export const replyList = (data) => {
  return request('admin/reply_list', 'get', data)
}
// 发短信
export const sendMsg = (data) => {
  return request('api/send_msg', 'post', data)
}
