<template>
  <div class="containe">
    <div class="user-box">
      <div class="user-item" v-for="item in props.list" :key="item" @click="privater(item)">
        <img :src="item.portrait" alt="" />
        <p>{{ item.nickname }}</p>
      </div>
      <div class="user-item" @click="add">
        <el-icon style="cursor: pointer; width: 48px; height: 48px; border: 1px dashed #aaa">
          <Plus />
        </el-icon>
        <p>添加</p>
      </div>
    </div>
    <div class="line"></div>
    <div style="margin: 20px 0">
      <p style="margin-bottom: 10px">群聊名称</p>
      <input type="text" v-model="name" @blur="inputBlur($event)" @input="input($event)" />
      <!-- <el-input
        type="text"
        v-model="props.groupname"
        class="w-50 m-2"
        size="large"
        placeholder="修改群名"
        @blur="inputBlur($event)"
      ></el-input> -->
    </div>
    <div class="btn" @click="dissolutionProup">解散群聊</div>
    <el-dialog v-model="dialogVisible" width="30%" :show-close="false">
      <div class="dialog-box">
        <div class="search">
          <el-input
            type="text"
            v-model="keyword"
            class="w-50 m-2"
            size="large"
            placeholder="请输入用户账户"
          >
            <template #suffix>
              <el-icon class="el-input__icon">
                <component :is="Search" class="enter-sj" @click="loadMap"></component>
              </el-icon>
            </template>
          </el-input>
        </div>
        <div class="user-list" v-if="serviceList.length > 0">
          <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="(item, index) in serviceList" :key="index" :label="item">
              <div style="display: flex; align-items: center">
                <div style="margin-right: 10px">
                  <p class="name">{{ item.nickname }}</p>
                  <p class="mobile">{{ item.mobile }}</p>
                </div>
                <div
                  style="width: 8px; height: 8px; border-radius: 50%; background: #00e152"
                  v-if="item.is_online == 1"
                ></div>
                <div
                  style="width: 8px; height: 8px; border-radius: 50%; background: #bfc2cb"
                  v-if="item.is_online == 0"
                ></div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="user" v-if="userList.length > 0">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 10px;
              cursor: pointer;
            "
            v-for="(item, index) in userList"
            :key="index"
            @click="selectUser(item)"
          >
            <div>
              <div>
                <span class="name">{{ item.nickname }}</span>
                <span class="name" v-if="item.user_type == 1">-用户</span>
                <span class="name" v-if="item.user_type == 2">-商户</span>
                <span class="name" v-if="item.user_type == 3">-客服</span>
              </div>
              <p class="mobile">{{ item.mobile }}</p>
            </div>
            <p @click="collect(item)" style="cursor: pointer">收藏</p>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancellation">取消</el-button>
          <el-button type="primary" @click="submit" v-if="serviceList.length > 0">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { Plus, Search, EditPen } from '@element-plus/icons-vue'
import { chatGroup, joinGroup, editGroup, exitGroup, getOnlineChatService } from '@/api/service'
import { ElMessage } from 'element-plus'
const props = defineProps({
  list: Array,
  groupId: Number,
  groupname: String
})
const name = ref(props.groupname)
const emit = defineEmits('userChange')
const dialogVisible = ref(false)
const keyword = ref('')
const userInfo = ref({})
const userList = ref([])
const serviceList = ref([])
const checkList = ref([])

const unique = (arr) => {
  const res = new Map()
  return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
}
const collect = (item) => {
  const list = []
  if (localStorage.getItem('collectList')) {
    let collectList = JSON.parse(localStorage.getItem('collectList'))
    collectList.unshift(item)
    collectList = unique(collectList)
    if (collectList.length <= 10) {
      localStorage.setItem('collectList', JSON.stringify(collectList))
    } else {
      const arry = []
      arry.push(collectList[0])
      arry.push(collectList[1])
      arry.push(collectList[2])
      arry.push(collectList[3])
      arry.push(collectList[4])
      arry.push(collectList[5])
      arry.push(collectList[6])
      arry.push(collectList[7])
      arry.push(collectList[8])
      arry.push(collectList[9])
      localStorage.setItem('collectList', JSON.stringify(arry))
    }
  } else {
    list.unshift(userInfo.value)
    localStorage.setItem('collectList', JSON.stringify(list))
  }
  ElMessage.success('收藏成功')
}
const getService = () => {
  getOnlineChatService().then((res) => {
    if (res.code == 0) {
      serviceList.value = res.data
      if (localStorage.getItem('collectList')) {
        const collectArry = JSON.parse(localStorage.getItem('collectList'))
        collectArry.forEach((i) => {
          serviceList.value.push(i)
        })
      }
    }
  })
}
const inputBlur = (even) => {
  editGroup({ group_id: props.groupId, title: name.value }).then((res) => {
    if (res.code == 0) {
      emit('userChange', false, 'edit', name.value)
    }
  })
}
const input = (e) => {
  props.groupname = e.data
}
const loadMap = () => {
  if (!keyword.value) {
    return false
  }
  chatGroup({ keyword: keyword.value }).then((res) => {
    if (res.code == 0) {
      if (res.data.data.length > 0) {
        serviceList.value = []
        userInfo.value = res.data.data[0]
        userList.value = res.data.data
      } else {
        ElMessage.error('暂无数据,请重新输入')
      }
    }
  })
}
const selectUserList = (i, index) => {
  let arry = []
  checkList.value.push(i.id)
  console.log(arry)
  console.log(checkList.value)
  checkList.value = Array.from(new Set(checkList.value))
  console.log(checkList.value)
  // joinGroup({
  //   group_id: props.groupId,
  //   id: i.id,
  //   type: 3
  // }).then((res) => {
  //   if (res.code == 0) {
  //     ElMessage.success(res.msg)
  //     emit('userChange', false, 'add')
  //     dialogVisible.value = false
  //   } else {
  //     ElMessage.error(res.msg)
  //   }
  // })
}
const submit = () => {
  let users = []
  for (let i = 0; i < checkList.value.length; i++) {
    let obj = {}
    obj.id = checkList.value[i].id
    obj.type = checkList.value[i].user_type
    users.push(obj)
  }
  joinGroup({
    group_id: props.groupId,
    users: users
  }).then((res) => {
    if (res.code == 0) {
      ElMessage.success(res.msg)
      emit('userChange', false, 'add')
      dialogVisible.value = false
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const selectUser = (item) => {
  joinGroup({
    group_id: props.groupId,
    users: [{ id: item.id, type: item.user_type }]
  }).then((res) => {
    if (res.code == 0) {
      emit('userChange', false, 'add')
      dialogVisible.value = false
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const dissolutionProup = () => {
  exitGroup({ group_id: props.groupId }).then((res) => {
    if (res.code == 0) {
      emit('userChange', false, 'dissolution')
    }
  })
}
const add = () => {
  dialogVisible.value = true
  getService()
}
const cancellation = () => {
  keyword.value = ''
  dialogVisible.value = false
  userInfo.value = {}
}
const privater = (i) => {
  emit('userChange', false, 'privately', i.user_id, i.user_type)
}
</script>
<style lang="less" scoped>
.containe {
  position: absolute;
  top: 44px;
  right: 0;
  width: 260px;
  height: 555px;
  background: #ffffff;
  padding: 0 16px;
  box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 20px 0px 0px 20px;
  .user-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 24px;
    .user-item {
      width: 48px;
      text-align: center;
      margin-right: 12px;
      img {
        width: 48px;
        height: 48px;
      }
      p {
        width: 48px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
    .user-item:nth-of-type(4n + 0) {
      margin-right: 0;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    border: 1px solid #f2f2f2;
    margin-top: 40px;
  }
  .btn {
    width: 188px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #f24747;
    margin: 0 auto;
    background: #f1f2f3;
    border-radius: 8px;
    cursor: pointer;
  }
}
:deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
}
.user-list {
  max-height: 300px;
  overflow-y: scroll;
  .item {
    margin-top: 10px;
    // cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  background: #aaa;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 5px;
  background: #f1f2f3;
  -webkit-box-shadow: none;
}
:deep(.el-checkbox) {
  margin-top: 10px;
}
</style>
