<template>
  <div>
    <!-- <topNav></topNav> -->
    <div class="head" @click="Logout">退出</div>
    <div class="mess">
      <!--用户列表-->
      <div class="mess_user_list">
        <!-- 所搜 -->
        <div class="search">
          <el-input
            type="text"
            v-model="state.keywords"
            class="w-50 m-2"
            size="large"
            placeholder="搜索"
            @keyup.enter="loadMap"
          >
            <template #suffix>
              <!--   vue3图标使用方式  -->
              <el-icon class="el-input__icon">
                <component :is="Search" class="enter-sj" @click="loadMap"></component>
              </el-icon>
            </template>
          </el-input>
        </div>
        <!--用户本人-->
        <!-- <div class="user">
          <el-avatar :size="40" :src="userAvatar" style="margin: 5px"></el-avatar>
          <span>{{ userName }}</span>
        </div> -->
        <!--其他用户或群-->
        <div class="user_list" v-if="state.userList.length > 0">
          <div
            v-for="(item, index) in state.userList"
            :key="index"
            @click="showmessdlog(item, index)"
            class="user_list_item"
            :class="
              state.current == item.group_id ? 'active' : '' || item.is_top > 0 ? 'bg-color' : ''
            "
            @contextmenu.prevent="openMenu($event, item)"
          >
            <div class="avatar-box">
              <el-avatar :size="40" :src="item.portrait" style="margin: 5px"></el-avatar>
              <p v-if="item.unread > 0">{{ item.unread }}</p>
            </div>
            <div style="width: 210px">
              <div class="leftCont">
                <p>{{ item.title }}</p>
                <!-- <el-input :disabled="inputDisabled" v-model="item.title"></el-input> -->
                <p style="text-align: right">
                  {{ timestampFormat(item.update_time) || '--:--' }}
                </p>

                <!-- <span>{{ item.name }}</span>
              <p
                v-if="messlst[item.id][messlst[item.id].length - 1].type !== 'null'"
                style="font-size: 10px; margin: 0px; margin-top: 4px"
              >
                [{{ messlst[item.id][messlst[item.id].length - 1].send_name }}]{{
                  messlst[item.id][messlst[item.id].length - 1].content
                }}
              </p>
              <p v-else style="font-size: 10px; margin: 0px; margin-top: 4px">暂无消息</p> -->
              </div>
              <div class="rightCont">
                <p
                  v-if="item.msg_type == 1"
                  v-html="delHtmlTag(item.msg)"
                  :style="{ color: item.isRemind ? 'red' : '' }"
                ></p>
                <p v-if="item.msg_type == 2">[图片]</p>
                <p v-if="item.msg_type == 3">[商品卡片]</p>
              </div>
            </div>
          </div>
          <div
            style="text-align: center; padding: 5px 0; cursor: pointer"
            @click="loadingClick"
            v-if="moreShow"
          >
            加载更多~
          </div>
          <ul
            v-if="rightClickItem"
            v-show="state.visible"
            :style="{
              left: position.left + 'px',
              top: position.top + 'px',
              display: visible ? 'block' : 'none'
            }"
            class="contextmenu"
          >
            <div class="item" v-show="rightClickItem.is_top == 0" @click.stop="copySvg(0)">
              置顶
            </div>
            <div class="item" v-show="rightClickItem.group_type == 1" @click.stop="copySvg(4)">
              备注
            </div>
            <div class="item" v-show="rightClickItem.is_top > 0" @click.stop="copySvg(1)">
              取消置顶
            </div>
            <div class="item" v-show="rightClickItem.group_type == 1" @click.stop="copySvg(2)">
              创建群聊
            </div>
            <div class="item" @click.stop="copySvg(3)">删除聊天</div>
          </ul>
        </div>
        <!-- <el-empty description="暂无数据" /> -->
      </div>
      <!--有对话时，对话框-->
      <div v-if="state.acceptUser !== ''" class="mess_dialog">
        <!--对话框头部-->
        <div class="dlog_header">
          <span>{{ state.acceptUser }}</span>
          <el-icon style="cursor: pointer" v-if="state.more" @click="moreClick">
            <MoreFilled />
          </el-icon>
        </div>
        <!--对话框内容-->
        <div class="dlog_content" ref="messageBox">
          <p v-if="msgMoreShow" style="text-align: center; cursor: pointer" @click="msgScroll">
            加载更多~
          </p>
          <div v-for="(item, index) in state.msgList" :key="index" class="dlog_content_item">
            <!--其他用户的消息展示-->
            <div class="content_other" v-if="item.from_user_id != user.id">
              <img :src="item.from_user.portrait" alt="" class="avatar" />
              <div class="content_msg">
                <div style="display: flex; align-items: center">
                  <p>{{ item.create_time }}</p>
                  <p style="margin-right: 5px">{{ item.from_user.nickname }}</p>
                  <el-button
                    type="primary"
                    v-if="item.from_user_type != 3"
                    @click="sendClick(item)"
                  >
                    发送
                  </el-button>
                </div>
                <p
                  style="
                    margin: 0px;
                    margin-top: 4px;
                    color: #1a1a1a;
                    font-size: 12px;
                    max-width: 400px;
                    word-wrap: break-word;
                  "
                  class="msg"
                  :style="{ color: item.msg.indexOf(user.nickname) != -1 ? 'red' : '' }"
                  v-if="item.type == 1"
                  v-html="msgHtml(item.msg)"
                  @click="hrefClick(item)"
                ></p>
                <template v-if="item.type == 2">
                  <el-image
                    v-if="item.isImg == true"
                    style="max-width: 400px; max-height: 300px"
                    :src="item.msg"
                    :zoom-rate="1.2"
                    :preview-src-list="[item.msg]"
                    fit="cover"
                  />
                  <video
                    v-else
                    :src="item.msg"
                    alt=""
                    controls
                    autoplay
                    style="max-width: 400px; max-height: 300px"
                  ></video>
                </template>
                <div v-if="item.type == 3" class="card" @click="orderClick(item.msg)">
                  <img :src="item.msg.image" alt="" />
                  <div>
                    <div class="title">{{ item.msg.title }}</div>
                    <div>商品编号:{{ item.msg.id }}</div>
                    <div class="price">￥{{ item.msg.price / 100 }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!--本用户的消息展示-->
            <div class="content_me" v-if="item.from_user_id == user.id">
              <div class="content_msg">
                <div style="display: flex">
                  <p>{{ item.create_time }}</p>
                  <p>{{ user.nickname }}</p>
                </div>
                <p
                  class="msg"
                  style="
                    margin: 0px;
                    margin-top: 4px;
                    color: #1a1a1a;
                    font-size: 12px;
                    max-width: 400px;
                    word-wrap: break-word;
                  "
                  v-if="item.type == 1"
                  v-html="msgHtml(item.msg)"
                  @click="hrefClick(item)"
                ></p>
                <template v-if="item.type == 2">
                  <el-image
                    v-if="item.isImg == true"
                    style="max-width: 400px; max-height: 300px"
                    :src="item.msg"
                    :zoom-rate="1.2"
                    :preview-src-list="[item.msg]"
                    fit="cover"
                  />
                  <!-- <video controls width="250" v-else>
                    <source :src="item.msg" type="video/mp4" />
                  </video> -->
                  <video
                    v-else
                    :src="item.msg"
                    alt=""
                    controls
                    autoplay
                    style="max-width: 400px"
                  ></video>
                </template>
                <div v-if="item.type == 3"></div>
              </div>

              <img :src="user.portrait" alt="" class="avatar" />
            </div>
          </div>
        </div>
        <!--对话框底部-->
        <div class="dlog_footer">
          <!-- <el-input type="textarea" :rows="5" v-model="state.mess"></el-input> -->
          <div class="img">
            <emoji @emojiMsg="emojiMsg"></emoji>
            <serviceUpload @msgImage="msgImage"></serviceUpload>
            <convenientReply @replyChang="reply"></convenientReply>
            <!-- <img src="../../assets/images/upload-icon.png" alt="" /> -->
          </div>
          <div
            :contenteditable="true"
            class="text-area"
            ref="edit"
            @paste="handlePaste($event)"
            @input="changeText"
            @keydown.enter="Wssendmess"
            @keyup="remind($event)"
          ></div>
          <div class="group-box" v-show="state.remindShow">
            <div
              class="item"
              v-for="(i, index) in state.groupUserList"
              :key="index"
              @click="selectRemind(i)"
            >
              {{ i.nickname }}
            </div>
          </div>
          <div style="text-align: right">
            <el-button type="primary" @click="Wssendmess">发送</el-button>
          </div>
        </div>
      </div>
      <!--无对话时，对话框-->
      <div v-else class="mess_dialog_false">
        <span>暂无消息，请选择用户对象</span>
      </div>
      <group-user
        v-if="state.groupShow"
        :list="state.groupUserList"
        :groupId="state.groupId"
        @userChange="selectChang"
        :groupname="state.acceptUser"
      ></group-user>
    </div>
    <el-dialog v-model="dialogVisible" title="修改备注" width="30%" :before-close="handleClose">
      <div style="border: 1px solid">
        <el-input v-model="remark" placeholder="Please input" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- <LayoutFooter></LayoutFooter> -->
  </div>
</template>
<script setup>
// import topNav from '../Layout/components/top-nav.vue'
// import LayoutFooter from '../Layout/components/layout-footer.vue'
import emoji from '../../components/emoji/emoji.vue'
import serviceUpload from '../../components/service-upload/service-upload.vue'
import convenientReply from '../../components/convenient-reply/convenient-reply.vue'
import groupUser from '../../components/group-user/group-user.vue'
import { ref, reactive, onMounted, watch, nextTick, onUnmounted, onUpdated } from 'vue'
import { MoreFilled, Search } from '@element-plus/icons-vue'
import {
  chatGroup,
  chatLogSingle,
  chatLogGroup,
  chatLogPage,
  chatGroupPage,
  chatGroupUser,
  groupMembers,
  operateGroup,
  joinGroup,
  createGroup,
  operateMsg,
  editSingle
} from '@/api/service'
import { sendMsg } from '@/api/upload'
import { timestampFormat } from '../../utils/formatDate'
import { fa } from 'element-plus/es/locale'
import router from '@/router'
import { ElMessage } from 'element-plus'
//发送短信
const sendClick = (i) => {
  sendMsg({ mobile: i.from_user.mobile }).then((res) => {
    if (res.code == 200) {
      ElMessage.success(res.msg)
    } else {
      ElMessage.error(res.msg)
    }
  })
}
function delHtmlTag(str) {
  return str.replace(/<[^>]+>/g, '')
}
function msgHtml(str) {
  var reg = /((http|https):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|])/g
  var textR = str.replace(
    reg,
    "<a href='$1' target='_blank' style='color: #00a0e9;width: 360px;display: inline;'>$1</a>"
  )
  return textR
}
function reduction(arr) {
  const res = new Map()
  return arr.filter((arr) => !res.has(arr.group_id) && res.set(arr.group_id, arr.group_id))
}
const user = JSON.parse(localStorage.getItem('user'))
const scoket = reactive({
  ws: null,
  isConnected: false,
  heartbeatInterval: null // 保存心跳定时器的ID
})
const messageBox = ref()
const msgpage = ref(1)
const dialogVisible = ref(false)
const remark = ref('')
const msgScroll = () => {
  state.startId = state.msgList[0].id
  getChatLog()
}
const initConnect = () => {
  scoket.ws = new WebSocket('wss://kf-api.sybaopei.com/socket/')
  scoket.ws.onopen = () => {
    console.log('WebSocket连接已建立')
    scoket.ws.send(
      JSON.stringify({
        type: 'bind',
        token: localStorage.getItem('token')
      })
    )
    scoket.isConnected = true
    scoket.heartbeatInterval = setInterval(() => {
      if (scoket.ws.readyState === 1) {
        scoket.ws.send(JSON.stringify({ type: 'ping' }))
        // console.log('心跳开启')
      } else {
      }
    }, 30000)
  }
  scoket.ws.onmessage = (event) => {
    // console.log(event)
    const dataMsg = JSON.parse(event.data)
    console.log(dataMsg, '接收到的信息')
    if (dataMsg.code == 200) {
      if (dataMsg.type == 'msg') {
        // userpage.value = 1
        // getUserList()
        let arry = []
        let obj = {}
        const time = Math.round(new Date().getTime() / 1000).toString()
        let flag = 1
        state.userList.forEach((i, index) => {
          if (i.is_top > 0) {
            arry.push(i)
          }
          if (i.uid == dataMsg.data.suid) {
            flag = 0
            obj = i
            state.userList.splice(index, 1)
            obj.msg = dataMsg.data.msg
            obj.msg_type = dataMsg.data.msg_type
            obj.update_time = time
            obj.unread++
            if (obj.is_top > 0) {
              state.userList.unshift(obj)
            } else {
              if (arry.length > 0) {
                state.userList.splice(arry.length, 0, obj)
              } else {
                state.userList.unshift(obj)
              }
            }
            console.log(state.userList)
          }
        })
        if (flag == 1) {
          chatGroupPage({ page: 1 }).then((res) => {
            if (res.code == 0) {
              let msgData = res.data.data
              msgData.forEach((i, index) => {
                if (i.uid == dataMsg.data.suid) {
                  console.log(i)
                  obj = i
                }
              })
              console.log(obj)
              if (obj.is_top > 0) {
                state.userList.unshift(obj)
              } else {
                if (arry.length > 0) {
                  state.userList.splice(arry.length, 0, obj)
                } else {
                  state.userList.unshift(obj)
                }
              }
            }
          })
          // console.log(state.userList)
        }
        if (state.uid == dataMsg.data.suid) {
          chatLogPage({ group_id: state.groupId, page: 1 }).then((res) => {
            if (res.code == 0) {
              let index = res.data.data.length
              state.msgTotal = res.data.count
              res.data.data[index - 1].msgurl = ''
              if (res.data.data[index - 1].type == 3) {
                res.data.data[index - 1].msg = JSON.parse(res.data.data[index - 1].msg)
              }
              res.data.data[index - 1].isImg = false
              if (res.data.data[index - 1].type == 2) {
                let arr = res.data.data[index - 1].msg.split('.')
                if (
                  arr[arr.length - 1] == 'png' ||
                  arr[arr.length - 1] == 'jpg' ||
                  arr[arr.length - 1] == 'jpeg' ||
                  arr[arr.length - 1] == 'webp' ||
                  arr[arr.length - 1] == 'gif' ||
                  arr[arr.length - 1] == 'bmp'
                ) {
                  res.data.data[index - 1].isImg = true
                }
              }
              state.msgList.push(res.data.data[index - 1])
            }
          })
          console.log(state.msgList)
        }
      } else if (dataMsg.op_type == 'create_group') {
        let data = dataMsg.data
        let arry = []
        for (let i = 0; i < state.userList.length; i++) {
          if (state.userList[i].is_top > 0) {
            arry.push(state.userList[i])
          }
        }
        if (arry.length > 0) {
          state.userList.splice(arry.length, 0, data)
        } else {
          state.userList.unshift(data)
        }
      } else if (dataMsg.op_type == 'ungroup') {
        state.userList.forEach((i, index) => {
          if (i.uid == dataMsg.data.uid) {
            state.userList.splice(index, 1)
          }
        })
      } else if (dataMsg.op_type == 'edit_group') {
        state.userList.forEach((i, index) => {
          if (i.uid == dataMsg.data.uid) {
            i.title = dataMsg.data.title
            if (state.uid == dataMsg.data.uid) {
              state.acceptUser = dataMsg.data.title
            }
          }
        })
      } else if (dataMsg.type == 'conversation') {
        // chatGroupPage({ page: 1 }).then((res) => {
        //   if (res.code == 0) {
        //     console.log(res.data.data)
        //   }
        // })
      }
    }
  }
  scoket.ws.onerror = () => {
    console.error('WebSocket连接发生错误')
  }
  scoket.ws.onclose = () => {
    console.log('WebSocket连接已关闭')
    scoket.isConnected = false
    // 清除心跳定时器
    clearInterval(scoket.heartbeatInterval)
    initConnect()
  }
}
// console.log(scoket.ws)
onMounted(() => {
  initConnect()
  // window.addEventListener('scroll', msgScroll)
})
onUnmounted(() => {
  scoket.ws.onclose = () => {
    console.log('WebSocket连接已关闭')
    scoket.ws.send('close')
    scoket.isConnected = false
    clearInterval(scoket.heartbeatInterval)
  }
})
const hrefClick = (i) => {
  if (i.msgurl) {
    window.open(i.msgurl)
  }
}
const getChatLog = () => {
  let params = {
    group_id: state.groupId
  }
  if (state.startId) {
    params.start_id = state.startId
  } else {
    params.page = msgpage.value
  }
  chatLogPage(params).then((res) => {
    if (res.code == 0) {
      // state.msgTotal = res.data.count
      if (res.data.data.length > 0) {
        msgMoreShow.value = true
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i].msgurl = ''
          if (res.data.data[i].type == 3) {
            res.data.data[i].msg = JSON.parse(res.data.data[i].msg)
          }
          res.data.data[i].isImg = false
          if (res.data.data[i].type == 2) {
            let arr = res.data.data[i].msg.split('.')
            if (
              arr[arr.length - 1] == 'png' ||
              arr[arr.length - 1] == 'jpg' ||
              arr[arr.length - 1] == 'jpeg' ||
              arr[arr.length - 1] == 'webp' ||
              arr[arr.length - 1] == 'gif' ||
              arr[arr.length - 1] == 'bmp'
            ) {
              res.data.data[i].isImg = true
            }
          }
        }

        if (!state.startId) {
          state.msgList = res.data.data
          nextTick(() => {
            messageBox.value.scrollTop = messageBox.value.scrollHeight
            console.log(messageBox.value.scrollTop)
          })
        } else {
          // messageBox.value.scrollTop = messageBox.value.scrollHeight
          state.msgList = [...res.data.data, ...state.msgList]
          console.log(messageBox.value)
        }
      } else {
        msgMoreShow.value = false
      }
    }
  })
}

onUpdated(() => {})
// watch(
//   () => scoket.isConnected,
//   (val) => {
//     if (val == false) {
//       initConnect()
//     }
//   }
// )
const state = reactive({
  //左侧列表
  userList: [],
  acceptUser: '',
  current: 0, //选中当前
  mess: '', //输入框内容
  visible: false,
  keywords: '', //创建聊天参数
  groupId: null, //聊天组id
  uid: '', //消息接收者
  //右侧列表
  msgList: [], //消息列表
  portraits: '', //当前选中聊天对象头像
  more: false,
  groupShow: false, //是否显示群聊详情
  groupUserList: [], //群成员列表数据
  socketType: '', //聊天类型
  remindShow: false,
  startId: null,
  msgTotal: null //消息总数
})
const position = ref({
  top: 0,
  left: 0
})
const rightClickItem = ref()
const edit = ref(null)
const userpage = ref(1)
const moreShow = ref(true)
const msgMoreShow = ref(true)
const loadingClick = () => {
  userpage.value++
  chatGroupPage({ page: userpage.value }).then((res) => {
    if (res.code == 0) {
      if (res.data.data.length > 0) {
        state.userList = [...state.userList, ...res.data.data]
        state.userList = reduction(state.userList)
      } else {
        moreShow.value = false
        // userpage.value = 1
      }
    }
  })
}
const showmessdlog = (i, index) => {
  console.log(i)
  // msgpage.value = 1
  state.startId = null
  state.msgList = []
  state.acceptUser = i.title
  state.current = i.group_id
  state.groupId = i.group_id
  state.uid = i.uid
  state.portraits = i.portrait
  if (i.group_type == 2) {
    state.more = true
  } else {
    state.more = false
  }
  if (edit.value) {
    edit.value.innerHTML = ''
  }
  state.groupShow = false
  if (i.group_type == 1) {
    state.socketType = 'single'
  } else if (i.group_type == 2) {
    state.socketType = 'group'
  }
  if (i.unread > 0) {
    operateMsg({ group_id: i.group_id }).then((res) => {
      if (res.code == 0) {
        state.userList[index].unread = 0
      }
    })
  }
  getChatLog()
}
//右键菜单
const openMenu = (e, i) => {
  console.log(i)
  state.visible = true
  position.value.left = e.layerX
  position.value.top = e.layerY
  rightClickItem.value = i
}
//会话操作
const copySvg = (val) => {
  let type = null
  userpage.value = 1
  if (val == 0 || val == 1) {
    type = 1
    operateGroup({ group_id: rightClickItem.value.group_id, type: 1 }).then((res) => {
      if (res.code == 0) {
        getUserList()
        state.visible = false
      }
    })
  } else if (val == 2) {
    let id = null
    let type = null
    if (rightClickItem.value.user_id == user.id && rightClickItem.value.user_type == 3) {
      id = rightClickItem.value.producer
      type = rightClickItem.value.producer_type
    } else {
      id = rightClickItem.value.user_id
      type = rightClickItem.value.user_type
    }
    createGroup({
      id: id,
      type: type
    }).then((res) => {
      if (res.code == 0) {
        getUserList()
        state.visible = false
      }
    })
  } else if (val == 3) {
    operateGroup({ group_id: rightClickItem.value.group_id, type: 0 }).then((res) => {
      if (res.code == 0) {
        getUserList()
        state.visible = false
      }
    })
  } else if (val == 4) {
    dialogVisible.value = true
    state.visible = false
  }
}
const submit = () => {
  editSingle({ group_id: rightClickItem.value.group_id, remark: remark.value }).then((res) => {
    if (res.code == 0) {
      ElMessage.success('操作成功')
      state.userList.forEach((i) => {
        if (i.group_id == rightClickItem.value.group_id) {
          i.title = remark.value
        }
      })
      dialogVisible.value = false
      remark.value = ''
    }
  })
}
const closeMenu = () => {
  state.visible = false
}
watch(
  () => state.visible,
  (val) => {
    if (val) {
      document.body.addEventListener('click', closeMenu)
    } else {
      document.body.removeEventListener('click', closeMenu)
    }
  }
)
const selectChang = (val, e, o, type) => {
  if (e == 'dissolution' || e == 'add') {
    // state.current = 0
    getUserList()
  } else if (e == 'edit') {
    state.acceptUser = o
    getUserList()
  } else if (e == 'privately') {
    createChat(o, type)
  }
  state.groupShow = val
}
const emojiMsg = (val) => {
  // console.log(val)
  edit.value.innerText += val
  // console.log(edit.value)
}
const reply = (val) => {
  edit.value.innerText += val
  // Wssendmess()
}
const moreClick = () => {
  state.groupShow = !state.groupShow
  if (state.groupShow == true) {
    groupMembers({ page: 1, limit: 100, group_id: state.groupId }).then((res) => {
      if (res.code == 0) {
        state.groupUserList = res.data.data
      }
    })
  }
}
//搜索
const loadMap = () => {
  userpage.value = 1
  getUserList()
  // if (state.keywords) {
  //   chatGroupPage({ page: userpage.value, keywords: keywords.value }).then((res) => {
  //     if (res.code == 0) {

  //     }
  //   })
  // } else {

  // }
  // let list = []
  // state.userList.filter((item) => {
  //   if (item.title.indexOf(state.account) !== -1) {
  //     list.push(item)
  //   }
  // })
  // state.userList = list
}
//创建会话
const createChat = (id, type) => {
  chatGroupUser({ id: id, type: type }).then((res) => {
    if (res.code == 0) {
      getUserList()
    }
  })
}
//获取聊天列表
const getUserList = () => {
  let params = {
    page: userpage.value
  }
  if (state.keywords) {
    params.keywords = state.keywords
  }
  chatGroupPage(params).then((res) => {
    if (res.code == 0) {
      if (res.data.data.length < res.data.count) {
        moreShow.value = true
      } else {
        moreShow.value = false
      }
      if (res.data.data.length > 0) {
        if (state.current == 0) {
          state.current = res.data.data[0].group_id
          state.portraits = res.data.data[0].portrait
          state.groupId = res.data.data[0].group_id
          state.acceptUser = res.data.data[0].title
          state.uid = res.data.data[0].uid
          if (res.data.data[0].group_type == 2) {
            state.more = true
            state.socketType = 'group'
          } else if (res.data.data[0].group_type == 1) {
            state.socketType = 'single'
          }
        }
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].group_type == 2) {
            if (res.data.data[i].msg.indexOf(user.nickname) != -1) {
              res.data.data[i].isRemind = true
            } else {
              res.data.data[i].isRemind = false
            }
            try {
              if (state.current != res.data.data[i].group_id) {
                const lastMsg = state.userList.filter((e) => {
                  return e.group_id == res.data.data[i].group_id
                })[0]
                lastMsg.isRemind ? (res.data.data[i] = lastMsg) : null
              }
            } catch (e) {}
          }
        }
        state.userList = res.data.data
      } else {
        if (userpage.value == 1) {
          state.userList = []
        }
        userpage.value = 1
      }
      getChatLog()
      // state.userList = [...state.userList, ...res.data.data]
    }
  })
}
getUserList()
const msgImage = (e) => {
  scoket.ws.send(
    JSON.stringify({
      type: 'send',
      msg: e,
      msg_type: 2,
      uid: state.uid,
      mode: state.socketType
    })
  )
  let params = {
    msg: e,
    msg_type: 2,
    group_id: state.groupId
  }
  chatLogSingle(params).then((res) => {
    if (res.code == 0) {
      state.userList.forEach((i, index) => {
        if (i.uid == state.uid) {
          i.msg = e
        }
      })
      edit.value.innerHTML = ''
      if (state.more == false) {
        chatLogPage({ group_id: state.groupId, page: 1 }).then((res) => {
          if (res.code == 0) {
            let index = res.data.data.length
            state.msgTotal = res.data.count
            res.data.data[index - 1].msgurl = ''
            if (res.data.data[index - 1].type == 3) {
              res.data.data[index - 1].msg = JSON.parse(res.data.data[index - 1].msg)
            }
            res.data.data[index - 1].isImg = false
            if (res.data.data[index - 1].type == 2) {
              let arr = res.data.data[index - 1].msg.split('.')
              if (
                arr[arr.length - 1] == 'png' ||
                arr[arr.length - 1] == 'jpg' ||
                arr[arr.length - 1] == 'jpeg' ||
                arr[arr.length - 1] == 'webp' ||
                arr[arr.length - 1] == 'gif' ||
                arr[arr.length - 1] == 'bmp'
              ) {
                res.data.data[index - 1].isImg = true
              }
            }
            state.msgList.push(res.data.data[index - 1])
          }
        })
      }
    }
  })
}
//发送按钮
const Wssendmess = () => {
  // single 单聊
  // group  群聊
  scoket.ws.send(
    JSON.stringify({
      type: 'send',
      msg: edit.value.innerHTML,
      msg_type: 1,
      uid: state.uid,
      mode: state.socketType
    })
  )
  let params = {
    msg: edit.value.innerHTML,
    msg_type: 1,
    group_id: state.groupId
  }
  chatLogSingle(params).then((res) => {
    if (res.code == 0) {
      state.userList.forEach((i, index) => {
        if (i.uid == state.uid) {
          i.msg = edit.value.innerHTML
        }
      })
      edit.value.innerHTML = ''
      if (state.more == false) {
        chatLogPage({ group_id: state.groupId, page: 1 }).then((res) => {
          if (res.code == 0) {
            let index = res.data.data.length
            state.msgTotal = res.data.count
            res.data.data[index - 1].msgurl = ''
            if (res.data.data[index - 1].type == 3) {
              res.data.data[index - 1].msg = JSON.parse(res.data.data[index - 1].msg)
            }
            res.data.data[index - 1].isImg = false
            if (res.data.data[index - 1].type == 2) {
              let arr = res.data.data[index - 1].msg.split('.')
              if (
                arr[arr.length - 1] == 'png' ||
                arr[arr.length - 1] == 'jpg' ||
                arr[arr.length - 1] == 'jpeg' ||
                arr[arr.length - 1] == 'webp' ||
                arr[arr.length - 1] == 'gif' ||
                arr[arr.length - 1] == 'bmp'
              ) {
                res.data.data[index - 1].isImg = true
              }
            }
            state.msgList.push(res.data.data[index - 1])
          }
        })
      }
    }
  })
}
//登出
const Logout = () => {
  localStorage.clear()
  router.push('/login')
}
const orderClick = (goods) => {
  window.open('https://buy.sybaopei.com/#/goods?id=' + goods.id + '&type=buy')
}
// const changeText = () => {
//   // 解决：末尾换行，看不见的<br>，删不掉，造成清空无法出现placeholder文字
//   if (this.$el.innerHTML == '<br>') {
//     this.$el.innerHTML = ''
//   }
//   // this.$emit('input', this.$el.innerHTML)
// }
const handlePaste = (e) => {
  console.log(e)
}
const remind = (e) => {
  if (e.key == '@') {
    groupMembers({ page: 1, limit: 100, group_id: state.groupId }).then((res) => {
      if (res.code == 0) {
        // for (let i = 0; i < res.data.data.length; i++) {
        //   if (res.data.data[i].user_type != 3) {
        //     const idCard = res.data.data[i].nickname
        //     const temp = idCard.substring(1)
        //     res.data.data[i].nickname = idCard.replace(temp, '****')
        //   }
        // }
        state.groupUserList = res.data.data
        state.remindShow = true
      }
    })
  }
}
const selectRemind = (i) => {
  edit.value.innerText += i.nickname + ' '
  state.remindShow = false
}
</script>
<style lang="less" scoped>
.head {
  background: #262626;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cccccc;
  text-align: right;
  cursor: pointer;
  padding-right: 20px;
}
.mess {
  position: relative;
  border-radius: 5px;
  background-clip: padding-box;
  margin: 20px auto;
  width: 950px;
  height: 600px;
  border: 1px #8a8282;
  box-shadow: 0 0 10px #9b9393;
  background-color: white;
  display: flex;
  .mess_user_list {
    width: 270px;
    height: 100%;
    overflow-y: scroll;
    //   background-color: #9f9c9c;
    .user {
      height: 60px;
      width: 270px;
      /*垂直居中*/
      display: flex;
      align-items: center;
      border-bottom: 1px solid #0a0a0a;
    }
    .user_list {
      height: 560px;
      // overflow: auto;
      overflow-y: scroll;
    }
    .user_list_item {
      height: 76px;
      //   background-color: #b3b3b4;
      border-bottom: 1px solid #f6f8fb;
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }
    .bg-color {
      background: rgb(228, 228, 230);
    }
    .active {
      background: url('~@/assets/images/current-bg.png') no-repeat;
      color: #ffffff;
      background-size: cover;
    }
    .avatar-box {
      position: relative;
      p {
        position: absolute;
        top: 0;
        right: 5px;
        background: #f93737;
        border-radius: 50%;
        font-size: 12px;
        color: #ffffff;
        padding: 0 3px;
      }
    }
    .leftCont {
      display: flex;
      justify-content: space-between;
      width: 210px;
      p {
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
    .rightCont {
      display: flex;
      justify-content: space-between;
      p:nth-child(1) {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
    }
  }
  .mess_dialog {
    width: 680px;
    height: 600px;

    .dlog_header {
      width: 680px;
      height: 44px;
      //   border-bottom: 1px solid #8a8282;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #f6f8fb;
      padding: 0 30px;
    }
    .dlog_content {
      width: 680px;
      height: 370px;
      background: #f1f2f3;
      //   border-bottom: 1px solid #8a8282;
      overflow: hidden;
      overflow-y: scroll;
      padding: 20px;
      .dlog_content_item {
        margin-bottom: 30px;
      }
      .content_other {
        // width: 650px;
        display: flex;
        // align-items: center;
        margin-top: 10px;
        position: relative;
        .avatar {
          width: 40px;
          height: 40px;
        }
        .content_msg {
          margin-left: 8px;
          user-select: text;
          -webkit-user-select: text;
          // p:nth-child(1) {
          //   font-size: 14px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          //   font-weight: 400;
          //   color: #666666;
          // }
          .msg {
            padding: 10px;
            background: #ffffff;
            border-radius: 4px 12px 12px 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            // position: absolute;
            // left: 0;
          }
        }
      }
      .content_me {
        // width: 650px;
        display: flex;
        margin-top: 10px;
        // align-items: center;
        justify-content: end;
        .avatar {
          width: 40px;
          height: 40px;
        }
        .content_msg {
          margin-right: 8px;
          user-select: text;
          -webkit-user-select: text;
          position: relative;
          // p:nth-child(1) {
          //   font-size: 14px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          //   font-weight: 400;
          //   color: #666666;
          //   text-align: right;
          // }
          .msg {
            padding: 10px;
            background: #ffffff;
            border-radius: 12px 4px 12px 12px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
            // position: absolute;
            // right: 0;
          }
        }
      }
    }
    .dlog_footer {
      width: 680px;
      height: 180px;
      border-left: 1px solid #f5f6fa;
      padding: 15px;
      position: relative;
      .img {
        display: flex;
        // margin-bottom: 5px;
      }
      .text-area {
        height: 100px;
        overflow: auto;
        user-select: text;
        -webkit-user-select: text;
      }
      .text-area:focus-visible {
        border: none;
      }
      // :deep(.el-button--primary) {
      //   // margin-right: 20px;
      // }
      .group-box {
        background: #ffffff;
        padding: 0 10px 10px 10px;
        position: absolute;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        top: 40px;
        left: 40px;
        z-index: 999;
        border-radius: 10px;
        height: 200px;
        overflow-y: auto;
        .item {
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
  }
  .mess_dialog_false {
    width: 680px;
    height: 600px;
    text-align: center;
    line-height: 600px;
    border-left: 1px solid #f5f6fa;
  }
}
:deep(.el-textarea__inner) {
  resize: none;
}
[contenteditable]:focus {
  outline: none;
}
:deep(.el-input__wrapper) {
  box-shadow: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  background: #aaa;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 5px;
  background: #f1f2f3;
  -webkit-box-shadow: none;
}

.contextmenu {
  width: 100px;
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  .item {
    padding: 0 15px;
    height: 35px;
    width: 100%;
    line-height: 35px;
    color: rgb(29, 33, 41);
    cursor: pointer;
  }
  .item:hover {
    background: rgb(229, 230, 235);
  }
}
.card {
  display: flex;
  padding: 12px;
  width: 280px;
  // height: 104px;
  background: #ffffff;
  border-radius: 12px 4px 12px 12px;
  img {
    width: 80px;
    height: 80px;
    margin-right: 5px;
  }
  .title {
    width: 170px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1a1a1a;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .price {
    font-size: 16px;
    font-family: DIN-Bold, DIN;
    font-weight: bold;
    color: #ff401b;
    text-align: right;
  }
}
</style>
