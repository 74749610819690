import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import router from '@/router'
// baseURL 超时时间配置
const instance = axios.create({
  // baseURL: 'http://192.168.31.182/'
  baseURL: 'https://api.sybaopei.com/'
  //baseURL: '/api'
  //timeout: 5000
})

// 全局注入token
instance.interceptors.request.use(
  (config) => {
    // 1. 获取token
    const token = localStorage.getItem('token')
    // 2. 请求头设置token
    if (token) config.headers.token = token
    return config
  },
  (e) => Promise.reject(e)
)

// token失效跳回到登录页
instance.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.code == 200) {
      return res
    } else if (res.code == 401) {
      // store.commit('login/loginVisible', true)
      router.push('/')
      return res
    } else {
      return res
    }
  },
  (error) => {
    //message.error(res.message || 'Error', 5000)
    return Promise.reject(error)
  }
)
/**
 * @param {String} - url  请求地址
 * @param {String} - method  请求类型
 * @param {Object} - submitData  对象类型，提交数据
 */
const request = (url, method, submitData) => {
  return instance({
    url,
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
  })
}

export default request
