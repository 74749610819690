<template>
  <div>
    <img src="../../assets/images/icon-service.png" alt="" @click="convenient" />
    <el-dialog v-model="dialogVisible" title="" width="30%" :show-close="false">
      <div>
        <p v-for="i in list" :key="i" v-html="i" @click="reply(i)"></p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { ChatLineSquare } from '@element-plus/icons-vue'
import { ref, reactive } from 'vue'
import { replyList } from '@/api/upload'
const dialogVisible = ref(false)
const emit = defineEmits('replyChang')
const convenient = () => {
  dialogVisible.value = true
  getList()
}
const list = ref()
const getList = () => {
  replyList().then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
    }
  })
}
const reply = (i) => {
  dialogVisible.value = false
  emit('replyChang', i)
}
</script>
<style lang="less" scoped>
img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
p {
  padding: 10px 0;
  cursor: pointer;
}
</style>
